exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-promotion-js": () => import("./../../../src/pages/promotion.js" /* webpackChunkName: "component---src-pages-promotion-js" */),
  "component---src-templates-casino-page-js": () => import("./../../../src/templates/CasinoPage.js" /* webpackChunkName: "component---src-templates-casino-page-js" */),
  "component---src-templates-fishing-page-js": () => import("./../../../src/templates/FishingPage.js" /* webpackChunkName: "component---src-templates-fishing-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-slot-page-js": () => import("./../../../src/templates/SlotPage.js" /* webpackChunkName: "component---src-templates-slot-page-js" */),
  "component---src-templates-sport-page-js": () => import("./../../../src/templates/SportPage.js" /* webpackChunkName: "component---src-templates-sport-page-js" */)
}

